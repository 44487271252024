/* CB Style Variables */
@import '../colors';

$primary: #1e476a;
$secondary: #b35c00;
$light: #d8d8d8;

$font-family-base: 'Open Sans', sans-serif;

/* Primary Page-Styling Variables */
$body-bg: #495058;
$body-color: #222;
$btn-pop-bg: $secondary;

$navbar-bg: #163753;
$footer-bg: #1e476a;

$link-color: #0000ff;

/* Proccess Page Styling */
$process-page-themes: (
  'claim': $primary,
  'holder': $primary
);

$state-theme-colors: (
  "orange": #FF9B2D,
  "danger": darken(#FF7D7D, 36%)
);
$theme-colors: map-merge($theme-colors, $state-theme-colors);

$dropdown-bg: #163753;
$dropdown-hover-bg: none;
$dropdown-divider-bg: #777;

$dropdown-link-color: #fff;
$dropdown-link-hover-color: #fff;
$dropdown-link-hover-bg: #1e476a;

$tooltip-icon-color: $primary;
$mobile-navbar-bg: #495058;
$static-page-header-color: $primary;
$static-page-subtitle-color: #333;

/* Image Size & Image Source Variables */
$banner-image-url: url('/images/Banner_Image.jpg');

$claim-header-logo-url: url('/images/process_logo.png');
$claim-header-logo-height: 100px;
$claim-header-logo-width: 180px;

$holder-header-logo-url: $claim-header-logo-url;
$holder-header-logo-height: $claim-header-logo-height;
$holder-header-logo-width: $claim-header-logo-width;

$home-logo-url: url('/images/static_logo.png');
$home-logo-width: 518px;
$home-logo-height: 49px;
$home-logo-resize-value-map: (
  width: 139px,
  height: 70px,
  background-image: url('/images/process_logo.png')
);

$treasurer-logo-url: $home-logo-url;
$treasurer-logo-width: $home-logo-width;
$treasurer-logo-height: $home-logo-height;
$treasurer-logo-resize-value-map: $home-logo-resize-value-map;

$footer-logo-url: url('/images/state_seal.png');
$footer-logo-width: 150px;
$footer-logo-height: 150px;

$claimform-image-url: url('/images/Sample_Claim_Form.png');

$icon-email-url: none;
$icon-facebook-url: url('/images/facebook.png');
$icon-instagram-url: none;
$icon-twitter-url: none;
$icon-youtube-url: none;
$icon-linkedin-url: none;

$social-icon-width: 50px;
$social-icon-height: 50px;

$btn-prop-claim-icon-size: 1.1rem;
$btn-prop-share-icon-size: 0.8125rem;

$btn-upload-color: $secondary;


.home-page > .home-banner {
  .card {
    > .card-img {
      min-height: 350px !important;
      background-size: cover !important;
      background-repeat: no-repeat;
    }
  }
}

.home-logo {
  background-repeat: no-repeat;
  background-size: contain !important;
}

// regex matches all buttons with an id that starts with file.
// This will capture all buttons on upload page file1 through file4
// Also had to override all pseudo classes for button
button[id^='file'],
button[id^='reportFile'] {
  background-color: $secondary;
  color: white;
  border: 1px solid $secondary;

  &:hover,
  &:active,
  &:focus {
    background-color: $secondary;
    color: white;
    border: 1px solid $secondary;
  }
}

sws-process-page {
  .pp-header-logo {
    background-repeat: no-repeat;
    background-size: contain !important;
  }
}

.dark-grey {
  background: rgb(149, 149, 149);
}

.dark-grey-border {
  border: 1px rgb(149, 149, 149) solid;
  background-color: white;
  padding: 1em;
}

.table-header-height {
  display: flex;
  align-items: center;
  height: 75px !important;
  justify-content: center;
}

#cartPreviewRemoveButton {
  color: $secondary !important;

  &:hover {
    color: darken($color: $secondary, $amount: 8%) !important;
  }
}


.nav-tabs-boc {
  border-bottom: 5px solid $primary !important;
  border-radius: 0px;

  li.nav-item {
    &:not(:last-child) {
      margin-right: 10px;
      @media (max-width: 776px) {
        margin-right: 0px;
      }
    }

    > a.nav-link {
      white-space: normal !important;
      border: 1px solid transparent;
      border-radius: 1px;
      color: $primary !important;
      background-color: white !important;
      border-color: $primary !important;
      text-transform: uppercase;
      font-weight: bold;
      font-size: 14px;
      padding: 10px 15px 10px 15px;

      &.active {
        color: white !important;
        background-color: $primary !important;
      }

      &:hover {
        color: white !important;
        background-color: $primary !important;
      }
    }
  }
}

.nav-subtitle {
  background-color: white !important;
  color: $primary !important;
  font-weight: bold !important;
  cursor: default !important;
}


// Custom css for yellow box on the claim-doc-upload page
.doc-submissions-req-yellow-box {
  @extend .bg-yellow;
  width: 95%;
  padding: 10px 20px 5px 20px;
  border-radius: 12px;
}

.bg-yellow {
  background-color: #fff2bc;
}

.bg-gray {
  background-color: #d8d8d8;
}

.dark-blue-header {
  color: $primary;
  margin-top: 15px;
}

.blue-bg {
  background-color: lightblue;
  padding-bottom: 5px;
  padding-top: 10px;
  padding-left: 10px;
  padding-right: 10px;
}

.cb-sp-title {
  color: $static-page-header-color;
  margin-top: 2rem;
}


